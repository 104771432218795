<template>
  <a-card :bordered="false" class="card-area">
    <div class="wrap thewidth">
      <div class="step_box">
        <div class="step_box_head">
          <div class="step_menu clearfix">
            <ul>
              <li :class="{checked:currentStep>=0}" id="auto_brand" @click="changeStep(0)"><span>1. 品牌选择</span></li>
              <li :class="{checked:currentStep>=1}" class="" id="auto_vehicle" @click="changeStep(1)">
                <span>2. 车系选择</span></li>
              <li :class="{checked:currentStep>=2}" class="" id="auto_model"><span>3. 车型选择</span></li>
            </ul>
          </div>
        </div>
        <car-brands v-show="currentStep==0" ref="carBrands" @selectBrand="selectBrand"></car-brands>
        <car-series v-show="currentStep==1" ref="carSeries" @selectSeries="selectSeries"/>
        <car-models v-show="currentStep==2" ref="carModels"/>
      </div>
    </div>
  </a-card>
</template>

<script>
import CarBrands from "@/components/car/CarBrands";
import CarSeries from "@/components/car/CarSeries";
import CarModels from "@/components/car/CarModels";

export default {
  name: "BrandList",
  components: {CarBrands, CarSeries,CarModels},
  data() {
    return {
      currentStep: 0,
    }
  }, computed: {},
  mounted() {

  },
  methods: {
    changeStep(step) {
      if (this.currentStep > step) {
        this.currentStep = step
      }
      if(this.currentStep==0){
        this.$refs.carBrands.resetIndex()
      }
    },
    async selectBrand(brand) {
      await this.$refs.carSeries.brandChanged(brand)
      this.currentStep = 1
    },
    async selectSeries(series) {
      await this.$refs.carModels.seriesChanged(series)
      this.currentStep = 2
    }
  }

}
</script>

<style scoped lang="less">

.step_box {
  margin: 20px auto;
  border: 1px #E5E5E5 solid;


  .step_box_head {
    border-bottom: 1px #E5E5E5 solid;
    color: #666;
    font-size: 16px;
    font-weight: 100;
    padding: 15px 40px 15px 0;
    background: #F5F5F5;
  }

  .step_menu {
    ul li {
      float: left;
      margin-right: 20px;
      border: 0 none;
      list-style: none;
      cursor: pointer;
    }

    ul li span {
      display: block;
      background: url(/static/images/step_menu.png) left bottom no-repeat;
      font-size: 14px;
      width: 146px;
      height: 35px;
      line-height: 35px;
      padding-left: 30px;
    }

    ul li.checked span {
      background: url(/static/images/step_menu.png) left top no-repeat;
      color: #FFF;
      border: 0 none;
    }
  }
}
</style>
